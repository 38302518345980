import { LoadingSolid } from '@motion/icons'
import { ButtonGroup, showToast, useShortcut } from '@motion/ui/base'
import { ProjectColoredIcon } from '@motion/ui/project'
import { useModalTitle } from '@motion/web-common/html'

import { useRouteConfirmationPromptBeforeLeaving } from '~/global/navigation'
import { type FormEvent } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router'

import { ExpandedProjectContent } from './expanded-project-content'
import { FormTemplateNameField } from './form-template-name-field'
import { FormWorkspaceDropdown } from './form-workspace-dropdown'

import { TaskProjectModalContent } from '../../../components/task-project-modal'
import { useProjectForm } from '../hooks'
import { type TemplateProjectFormFields } from '../types'

export type ProjectFormContentProps = {
  onClose(): void
}

export const ProjectFormContent = (props: ProjectFormContentProps) => {
  const { onClose } = props

  const navigate = useNavigate()
  const { templateProjectId: templateProjectIdParam } = useParams<{
    templateProjectId: string
  }>()

  const { form, options, actions } = useProjectForm()
  const { savedProject } = options
  const { submitTemplate, closeModal } = actions
  const { isDirty, isSubmitting } = form.formState

  const projectTemplateId = form.watch('templateId')
  const projectName = form.watch('name')
  const workspaceId = form.watch('workspaceId')
  const isLoading = form.watch('isLoading')

  useModalTitle(savedProject?.name)
  useRouteConfirmationPromptBeforeLeaving({ when: isDirty && !isSubmitting })

  const onFormSubmit = async (data: TemplateProjectFormFields) => {
    const updatedTemplateProject = await submitTemplate(data)
    form.reset({ templateName: '' }, { keepValues: true })

    // When saving a template from a project, we go back to the project view
    if (!templateProjectIdParam) {
      closeModal()
      return
    }

    if (templateProjectIdParam === 'new') {
      navigate(
        generatePath(
          '/web/settings/workspace/:workspaceId/templates/project/:templateId',
          { workspaceId, templateId: updatedTemplateProject.id }
        )
      )
    }

    return
  }

  const onSubmit = (e?: FormEvent<HTMLFormElement>) => {
    if (!isDirty) return

    void form.handleSubmit(onFormSubmit, (validationErrors) => {
      const errors = Object.values(validationErrors)
      if (errors.length < 1) return

      const message = errors[0].message
      if (typeof message !== 'string') return

      showToast('error', message)
    })(e)
  }

  useShortcut('mod+s', () => onSubmit())

  return (
    <TaskProjectModalContent
      onClose={onClose}
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      disableSubmit={!isDirty || isLoading || !projectName.trim()}
      actionButtonLabel={
        projectTemplateId != null ? 'Save template' : 'Create template'
      }
      renderLeftHeader={() => (
        <ButtonGroup size='small'>
          <ProjectColoredIcon className='h-3.5 w-3.5' />
          {!isLoading && <FormWorkspaceDropdown />}
        </ButtonGroup>
      )}
      renderLeftFooter={() => {
        return <FormTemplateNameField />
      }}
    >
      {isLoading ? (
        <div className='grow gap-3 p-3 w-[550px] h-[180px] grid place-items-center'>
          <LoadingSolid className='animate-spin text-semantic-neutral-icon-default' />
        </div>
      ) : (
        <ExpandedProjectContent />
      )}
    </TaskProjectModalContent>
  )
}
