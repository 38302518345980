import { type UploadedFileSchema } from '@motion/rpc-types'
import { showToast, ToasterState } from '@motion/ui/base'
import { getAttachmentStaticURL } from '@motion/ui-logic'
import { Sentry } from '@motion/web-base/sentry'
import { ModalDismissed, useModalApi } from '@motion/web-common/modals'

import { useDeleteAttachment } from '~/areas/attachments/hooks'
import { useDownloadAttachment } from '~/areas/attachments/hooks/use-download-attachment'
import { useCopyHtmlLinkToClipboard } from '~/global/hooks'

import { useCloseAttachmentModal } from '../modals/attachment-modal/hooks/use-close-attachment-modal'

type DeleteAttachmentResult = {
  deleted: boolean
}

export function useAttachmentActions() {
  const modalApi = useModalApi()

  const { mutate: deleteFile } = useDeleteAttachment()

  const { downloadFile } = useDownloadAttachment()

  const closeAttachmentModal = useCloseAttachmentModal()

  const copyToClipboard = useCopyHtmlLinkToClipboard()

  const deleteAttachment = async (
    attachment: UploadedFileSchema
  ): Promise<DeleteAttachmentResult> => {
    const response = await modalApi.prompt('confirm', {
      analytics: {
        name: 'delete-attachment',
      },
      destructive: true,
      title: 'Are you sure you want to delete this attachment?',
      description: 'This action is permanent.',
      confirmButtonText: 'Delete attachment',
    })

    if (response !== ModalDismissed) {
      try {
        await deleteFile(attachment)

        closeAttachmentModal()

        showToast('success', 'Attachment deleted successfully.')

        return { deleted: true }
      } catch (error) {
        Sentry.captureException(error, {
          tags: { position: 'deleteAttachment' },
          extra: {
            fileId: attachment.id,
          },
        })

        showToast('error', 'Failed to delete attachment. Please try again.')
      }
    }

    return { deleted: false }
  }

  const downloadAttachment = async (attachment: UploadedFileSchema) => {
    try {
      const toast = showToast('loading', 'Downloading attachment...')

      await downloadFile({
        fileId: attachment.id,
        fileName: attachment.fileName,
        mimeType: attachment.mimeType,
      })

      ToasterState.dismiss(toast)
    } catch (error) {
      Sentry.captureException(error, {
        tags: { position: 'downloadAttachment' },
        extra: {
          fileId: attachment.id,
        },
      })

      showToast('error', 'Failed to download attachment. Please try again.')
    }
  }

  const copyAttachmentLink = (attachment: UploadedFileSchema) => {
    if (!attachment.workspaceId) {
      showToast('error', 'Failed to copy attachment link. Please try again.')
      return
    }

    const attachmentUrl = getAttachmentStaticURL({
      workspaceId: attachment.workspaceId,
      attachmentId: attachment.id,
      taskId:
        (attachment.targetType === 'TEAM_TASK' && attachment.targetId) ||
        undefined,
      projectId:
        (attachment.targetType === 'PROJECT' && attachment.targetId) ||
        undefined,
    })

    copyToClipboard(attachmentUrl, attachment?.fileName || 'Attachment link')
  }

  return {
    deleteAttachment,
    downloadAttachment,
    copyAttachmentLink,
  }
}
