import { useSharedState } from '@motion/react-core/shared-state'

import { PageTasksContextKey } from '~/areas/tasks/context'
import { memo, useMemo } from 'react'

import { ConnectedProjectTasksPage } from './connected-project-task-page'
import { EmptyState } from './empty-state'
import { TaskTreeList } from './task-list'

import { LoadingPage } from '../../pm-revamp/common/loading-page'
import { usePageData } from '../routes'
import { useTaskGroupBy, useViewState } from '../view-state'

const ConnectedTaskListInternal = ({
  isArchivePage,
}: {
  isArchivePage: boolean
}) => {
  const [data] = useSharedState(PageTasksContextKey)
  const groupDefinitions = useTaskGroupBy()
  const [viewState] = useViewState()

  const filteredData = useMemo(() => {
    if (!data) return []
    if (!viewState.search) return data
    return data.filter((x) =>
      x.name.toLowerCase().includes(viewState.search.toLowerCase())
    )
  }, [data, viewState.search])

  if (data == null) {
    return <LoadingPage />
  }

  if (
    filteredData.length < 1 &&
    ((groupDefinitions.length > 0 && viewState.groupBy.hideEmpty) ||
      isArchivePage)
  ) {
    return <EmptyState target='tasks' isArchivePage={isArchivePage} />
  }

  return (
    <TaskTreeList
      tasks={filteredData}
      groupBy={groupDefinitions}
      hideEmptyGroups={viewState.groupBy.hideEmpty}
      hideInlineAddRow={isArchivePage}
    />
  )
}

const MemoizedConnectedTaskList = memo(ConnectedTaskListInternal)

export const ConnectedTaskList = () => {
  const pageData = usePageData()

  const isArchivePage = useMemo(
    () => pageData.variant === 'archive',
    [pageData.variant]
  )

  return (
    <ConnectedProjectTasksPage>
      <MemoizedConnectedTaskList isArchivePage={isArchivePage} />
    </ConnectedProjectTasksPage>
  )
}
