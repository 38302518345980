import { CalendarSolid, PlusSolid } from '@motion/icons'
import { isObjectNoneId } from '@motion/shared/identifiers'
import { classed } from '@motion/theme'
import { Button, IconButton, Tooltip, useContextMenu } from '@motion/ui/base'
import { formatToReadableWeekDayMonth } from '@motion/ui-logic'
import {
  getProjectStageFromDefinitionId,
  getStageVariant,
  isStageActive,
  type StageVariant,
} from '@motion/ui-logic/pm/project'
import {
  type ProjectSchema,
  type StageDefinitionSchema,
  type StageSchema,
} from '@motion/zod/client'

import { useIsFlowsM4Enabled } from '~/areas/flows'
import { useUpdateProjectStageDueDate } from '~/areas/project/hooks'
import { DeadlineDropdown } from '~/areas/project-management/components'
import { StageLabel } from '~/global/components/labels'
import { useProject, useStageDefinition } from '~/global/hooks'

import { GoToStageButton } from './go-to-stage-button'
import { type LookupArgs } from './lookup-header'
import {
  StageHeaderContextMenu,
  StageHeaderContextMenuContent,
} from './stage-header-context-menu'
import { ToggleTasksButton } from './toggle-tasks-button'

import { useSidebarTaskContext } from '../context'

type StageHeaderProps = LookupArgs

const SHOW_NAME_TOOLTIP_AFTER_LENGTH = 14

export function StageHeader({ groupId, toggle, row }: StageHeaderProps) {
  const stageDefinition = useStageDefinition(groupId)
  const { projectId } = useSidebarTaskContext()

  const project = useProject(projectId)
  const projectStage = getProjectStageFromDefinitionId(
    project,
    stageDefinition.id
  )

  const { handleContextMenu, ContextMenuPopover } = useContextMenu()

  if (
    isObjectNoneId(stageDefinition) ||
    project == null ||
    projectStage == null
  ) {
    return null
  }

  const isActive = isStageActive(project, stageDefinition.id)
  const stageVariant = getStageVariant(projectStage)

  const nameTooltipContent =
    stageDefinition.name.length > SHOW_NAME_TOOLTIP_AFTER_LENGTH
      ? stageDefinition.name
      : undefined

  return (
    <>
      <div
        className='flex flex-row items-center gap-1 flex-1 overflow-hidden'
        onContextMenu={
          stageVariant === 'skipped' ? undefined : handleContextMenu
        }
      >
        <Tooltip content={nameTooltipContent} asChild>
          <StageLabel
            value={stageDefinition}
            variant={stageVariant}
            active={isActive}
          />
        </Tooltip>

        {row?.subRows.length ? <ToggleTasksButton row={row} /> : null}
      </div>

      <StageHeaderEndContent
        toggle={toggle}
        projectStage={projectStage}
        project={project}
        stageDefinition={stageDefinition}
        stageVariant={stageVariant}
        isActive={isActive}
      />
      <ContextMenuPopover
        renderContent={({ close }) => (
          <StageHeaderContextMenuContent
            close={close}
            project={project}
            projectStage={projectStage}
            stageDefinition={stageDefinition}
          />
        )}
      />
    </>
  )
}

function StageHeaderEndContent({
  toggle,
  projectStage,
  project,
  stageDefinition,
  stageVariant,
  isActive,
}: Pick<LookupArgs, 'toggle'> & {
  projectStage: StageSchema
  project: ProjectSchema
  stageDefinition: StageDefinitionSchema
  stageVariant: StageVariant
  isActive: boolean
}) {
  const isFlowsM4Enabled = useIsFlowsM4Enabled()

  const { enableInlineAdd } = useSidebarTaskContext()
  const updateStageDueDate = useUpdateProjectStageDueDate()

  const canSwitchToStage = !isActive
  const showGoToStageButton = !isFlowsM4Enabled && canSwitchToStage

  if (stageVariant === 'skipped') {
    return (
      <EndContentContainer>
        <em className='text-semantic-neutral-text-subtle text-2xs'>Canceled</em>
      </EndContentContainer>
    )
  }

  return (
    <EndContentContainer>
      <DeadlineDropdown
        hideTimeOptions
        disableClearing
        value={projectStage.dueDate}
        onChange={(dueDate) => {
          if (dueDate == null) return
          updateStageDueDate(project.id, stageDefinition.id, dueDate)
        }}
        isValidDateOption={() => true}
        contextProps={{
          projectId: project.id,
          stageDefinitionId: projectStage.id,
        }}
        dropdownTarget='stage'
      >
        <Button size='xsmall' variant='muted' sentiment='neutral'>
          <CalendarSolid />
          <span className='whitespace-nowrap'>
            {formatToReadableWeekDayMonth(projectStage.dueDate)}
          </span>
        </Button>
      </DeadlineDropdown>

      {showGoToStageButton && (
        <GoToStageButton project={project} stageDefinition={stageDefinition} />
      )}

      {enableInlineAdd && (
        <IconButton
          icon={PlusSolid}
          size='xsmall'
          variant='muted'
          sentiment='neutral'
          onClick={() => toggle({ stageDefinitionId: stageDefinition.id })}
        />
      )}

      {isFlowsM4Enabled && (
        <StageHeaderContextMenu
          project={project}
          projectStage={projectStage}
          stageDefinition={stageDefinition}
        />
      )}
    </EndContentContainer>
  )
}

const EndContentContainer = classed(
  'div',
  'flex gap-1 flex-nowrap items-center overflow-hidden'
)
