import { type PMTaskBlockerType, type PMTaskType } from '@motion/rpc/types'
import { isRecurringTaskParent } from '@motion/ui-logic'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { type BlockerType } from '~/areas/tasks/hooks'

import { useOnUpdateTask } from './use-on-update-task'

type Args = {
  task: PMTaskType
  currentBlockersToCheckAgainst: PMTaskType[]
  /**
   * 'blockedBy' means that the handler 'blocker' is selected as blocking this task
   * 'blocks' means that the handler 'blocker' is selected as being blocked by this task
   */
  type: BlockerType
}

export function useTaskAddBlocker({
  task,
  currentBlockersToCheckAgainst,
  type,
}: Args) {
  const onUpdateTask = useOnUpdateTask()

  const addBlockerForBlockedBy = (blocker: PMTaskType) => {
    const blockingTasksIds = currentBlockersToCheckAgainst.map(
      (selectedTask) => ({
        blockedId: selectedTask.id,
      })
    )
    const newBlockingTasks: PMTaskBlockerType[] = [
      ...(blockingTasksIds ?? []),
      { blockedId: blocker.id },
    ]
    void onUpdateTask(task, { blockingTasks: newBlockingTasks })
  }

  const addBlockerForBlocks = (blocker: PMTaskType) => {
    if (
      isRecurringTaskParent(blocker) ||
      blocker.blockingTasks?.some((blockingTask) => blockingTask.id === task.id)
    )
      return

    const newBlockingTasks: PMTaskBlockerType[] = [
      ...(blocker.blockingTasks ?? []),
      { blockedId: task.id },
    ]
    void onUpdateTask(blocker, { blockingTasks: newBlockingTasks })
  }

  const addBlocker = (blocker: PMTaskType) => {
    recordAnalyticsEvent('PROJECT_MANAGEMENT_UPDATE_BLOCKERS')

    if (type === 'blockedBy') {
      addBlockerForBlockedBy(blocker)
    } else {
      addBlockerForBlocks(blocker)
    }
  }

  return addBlocker
}
