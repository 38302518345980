import { Button } from '@motion/ui/base'
import { templateStr } from '@motion/ui-logic'
import {
  type FilterTarget,
  useActiveFilter,
  useResetFilter,
} from '@motion/ui-logic/pm/data'

import { MotionLink } from '~/global/components'
import { useEmptyResultsIcon } from '~/global/hooks'
import { useProjectModalUrl, useTaskModalUrl } from '~/global/navigation'
import { useCurrentTeam } from '~/global/rpc/team'
import { useUriByRouteId } from '~/routing'

import { usePageData } from '../routes'

export interface EmptyStateProps {
  target: FilterTarget
  isArchivePage?: boolean
}

export function EmptyState({ target, isArchivePage = false }: EmptyStateProps) {
  const Illustration = useEmptyResultsIcon()

  const [filter] = useActiveFilter()
  const resetFilters = useResetFilter()
  const buildTaskModalUrl = useTaskModalUrl()
  const buildProjectModalUrl = useProjectModalUrl()
  const getRouteUri = useUriByRouteId()

  const pageData = usePageData()
  const isAllTasks = pageData.page === 'all-tasks'
  const isMyTasks = pageData.page === 'my-tasks'
  const isWorkspace = pageData.page === 'workspace'

  const isTeam = !!useCurrentTeam().data?.id

  const hasFilters =
    filter.tasks.ordered.length > 0 || filter.projects.ordered.length > 0
  const noFilterAllTasks = isAllTasks && !hasFilters && !isArchivePage
  const noFilterMyTasks = isMyTasks && !hasFilters && !isArchivePage
  const noFilterWorkspace = isWorkspace && !hasFilters && !isArchivePage

  return (
    <div className='grid place-items-center w-full h-full'>
      <div className='flex flex-col gap-4 items-center'>
        <Illustration className='w-32 h-32' />
        <span className='text-semantic-neutral-text-default font-semibold text-center'>
          {isArchivePage
            ? 'No archive tasks yet'
            : noFilterAllTasks
              ? isTeam
                ? 'This team is empty!'
                : 'Nothing here yet!'
              : noFilterMyTasks
                ? 'My Tasks is empty!'
                : noFilterWorkspace
                  ? 'This workspace is empty!'
                  : templateStr(
                      hasFilters ? 'No {{type}} found' : 'No {{type}} yet',
                      {
                        type: target === 'tasks' ? 'tasks' : 'projects',
                      }
                    )}
        </span>
        {!isArchivePage &&
          (noFilterAllTasks || noFilterMyTasks || noFilterWorkspace ? (
            <span className='text-semantic-neutral-text-default'>
              Add your first task or project using the buttons below
            </span>
          ) : (
            <span className='text-semantic-neutral-text-default'>
              {hasFilters
                ? templateStr('Some {{type}} may be hidden by filters.', {
                    type: target === 'tasks' ? 'tasks' : 'projects',
                  })
                : templateStr('Add your first {{type}} below!', {
                    type: target === 'tasks' ? 'task' : 'project',
                  })}
            </span>
          ))}
        {(noFilterAllTasks || noFilterMyTasks || noFilterWorkspace) && (
          <span className='text-semantic-neutral-text-default'>
            Or learn more about Motion Workspaces in the{' '}
            <MotionLink className='underline' url='/web/tutorials'>
              Tutorials Tab
            </MotionLink>
          </span>
        )}
        {isArchivePage ? (
          <Button
            sentiment='neutral'
            variant='outlined'
            url={getRouteUri('parent', { type: 'tasks' })}
          >
            Go back
          </Button>
        ) : noFilterAllTasks || noFilterMyTasks || noFilterWorkspace ? (
          <div className='flex gap-2 flex-nowrap pt-4'>
            <Button
              sentiment='primary'
              variant='solid'
              shortcut='P'
              url={buildProjectModalUrl()}
            >
              Add Project
            </Button>
            <Button
              sentiment='primary'
              variant='solid'
              shortcut='T'
              url={buildTaskModalUrl()}
            >
              Add Task
            </Button>
          </div>
        ) : hasFilters ? (
          <Button
            sentiment='neutral'
            variant='outlined'
            onClick={() => resetFilters()}
          >
            Clear filters
          </Button>
        ) : (
          <Button
            sentiment='primary'
            variant='solid'
            url={
              target === 'tasks' ? buildTaskModalUrl() : buildProjectModalUrl()
            }
            shortcut={target === 'tasks' ? 'T' : 'P'}
          >
            {target === 'tasks' ? 'Add Task' : 'Add Project'}
          </Button>
        )}
      </div>
    </div>
  )
}
