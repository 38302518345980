import { PMItemType, type PMTaskType } from '@motion/rpc/types'
import { type UpdateTeamTaskDto } from '@motion/rpc-types'
import { getAssigneeChangedFields } from '@motion/ui-logic/pm/task'

import { useWorkspaceMember } from '~/global/hooks'
import { useCallback } from 'react'

import { useOnUpdateTask } from './use-on-update-task'

export const useAssigneeDropdown = (task: PMTaskType) => {
  const onUpdateTask = useOnUpdateTask()

  const onChange = useCallback(
    async (assigneeUserId: UpdateTeamTaskDto['assigneeUserId']) => {
      const updates = {
        assigneeUserId,
        ...getAssigneeChangedFields({ ...task, assigneeUserId }),
      }

      await onUpdateTask(task, updates)
    },
    [onUpdateTask, task]
  )

  const isRecurringTask = task?.itemType === PMItemType.recurringTask

  const selectedAssignee = useWorkspaceMember(
    task.workspaceId,
    task.assigneeUserId
  )

  return {
    onChange,
    removeUnassignedOption: isRecurringTask,
    selectedAssignee,
    workspaceId: task.workspaceId,
  }
}
