import { ExternalLinkSolid, PlusSolid } from '@motion/icons'
import { classed } from '@motion/theme'
import { Button, IconButton, Tooltip } from '@motion/ui/base'
import { CustomEventHandlers, useRichTextEditor } from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { TemplateSidebarTasks } from '~/areas/project-management/components/template-sidebar-tasks'
import { useTaskModalUrl } from '~/global/navigation'
import { type ReactNode } from 'react'
import { useController } from 'react-hook-form'

import { FormAssigneeDropdown } from './form-assignee-dropdown'
import { FormDeadlineDropdown } from './form-deadline-dropdown'
import { FormDescriptionField } from './form-description-field'
import { FormLabelDropdown } from './form-label-dropdown'
import { FormNameField } from './form-name-field'
import { FormPriorityDropdown } from './form-priority-dropdown'
import { FormStartDateDropdown } from './form-start-date-dropdown'
import { FormStatusDropdown } from './form-status-dropdown'

import { RichTextToolbar } from '../../template-task-modal/components/richtext-toolbar'
import { useProjectForm } from '../hooks'

export const ExpandedProjectContent = () => {
  const { form, actions, options } = useProjectForm()
  const buildTaskModalUrl = useTaskModalUrl()
  const { uploadImage, openLinkModalPrompt } = actions

  const { control, watch } = form
  const { tasks } = options

  const { field } = useController({
    name: 'description',
    control,
    rules: {
      maxLength: {
        value: 100_000,
        message: 'The description should contain at most 100,000 characters',
      },
    },
  })

  const id = watch('id')
  const templateId = watch('templateId')
  const workspaceId = watch('workspaceId')
  const description = watch('description')

  const editor = useRichTextEditor({
    className: 'min-h-[250px]',
    placeholder: 'Description',
    value: description,
    onChange(value) {
      field.onChange(value)
    },
    extensions: [CustomEventHandlers(uploadImage)],
    onImgExpandClick: () => recordAnalyticsEvent('IMAGE_EXPAND_CLICK'),
  })

  if (!editor) return null

  const isEditingTemplate = templateId != null && templateId !== 'new'

  const showTasks = id != null
  const canAddTask = isEditingTemplate

  return (
    <div className='w-full grid grid-cols-[1fr_320px] grid-rows-[fit-content(0)_1fr]'>
      <div className='p-4'>
        <FormNameField />
      </div>

      <aside className='row-span-2 border-l border-modal-border overflow-auto flex flex-col gap-4 py-4'>
        <section className='flex flex-col gap-3 px-4'>
          <h3 className='text-sm font-semibold text-semantic-neutral-text-default'>
            Details
          </h3>
          <div className='gap-x-3 grid grid-cols-[100px_1fr] auto-rows-min items-start'>
            <SidebarRow label='Status'>
              <FormStatusDropdown variant='muted' fullWidth />
            </SidebarRow>
            <SidebarRow label='Assignee'>
              <FormAssigneeDropdown variant='muted' fullWidth />
            </SidebarRow>
            <SidebarRow label='Start Date'>
              <FormStartDateDropdown variant='muted' fullWidth />
            </SidebarRow>
            <SidebarRow label='Deadline'>
              <FormDeadlineDropdown variant='muted' fullWidth />
            </SidebarRow>
            <SidebarRow label='Priority'>
              <FormPriorityDropdown variant='muted' fullWidth />
            </SidebarRow>
            <SidebarRow label='Labels'>
              <FormLabelDropdown variant='muted' fullWidth />
            </SidebarRow>
          </div>
        </section>

        {showTasks && (
          <>
            <SidebarDivider />
            <section className='flex flex-col gap-3 px-4'>
              <h3 className='text-sm font-semibold text-semantic-neutral-text-default flex flex-row items-center justify-between'>
                Tasks
                <div className='flex items-center gap-1'>
                  <Tooltip
                    asChild
                    content={
                      !canAddTask &&
                      'Create a project template before adding template tasks'
                    }
                  >
                    <Button
                      variant='muted'
                      sentiment='neutral'
                      size='small'
                      url={
                        isEditingTemplate
                          ? `tasks/new`
                          : buildTaskModalUrl({ forProject: id })
                      }
                      disabled={!canAddTask}
                    >
                      <PlusSolid /> Add task
                    </Button>
                  </Tooltip>
                  <div className='w-px h-4 bg-button-neutral-outlined-icon-disabled' />
                  <Tooltip content='Open task list view' asChild>
                    <IconButton
                      icon={ExternalLinkSolid}
                      variant='muted'
                      sentiment='neutral'
                      size='small'
                      url={`/web/pm/workspaces/${workspaceId}/projects/${id}`}
                    />
                  </Tooltip>
                </div>
              </h3>
              <div className='gap-y-1 gap-x-3'>
                <TemplateSidebarTasks tasks={tasks} />
              </div>
            </section>
          </>
        )}
      </aside>

      <div className='flex flex-col gap-3 overflow-y-auto'>
        <div className='px-4 pb-4 relative z-10'>
          <div className='pt-1 pb-3 bg-modal-bg sticky top-0 z-10'>
            <RichTextToolbar
              editor={editor}
              onFileUpload={uploadImage}
              onLinkClick={openLinkModalPrompt}
            />
          </div>
          <FormDescriptionField editor={editor} />
        </div>
      </div>
    </div>
  )
}

type SidebarRowProps = {
  label: string
  children: ReactNode
}
function SidebarRow({ label, children }: SidebarRowProps) {
  return (
    <>
      <div className='text-sm text-semantic-neutral-text-subtle pt-1.5'>
        {label}
      </div>
      <div>{children}</div>
    </>
  )
}

const SidebarDivider = classed('div', {
  base: 'border-b border-semantic-neutral-border-subtle',
})
