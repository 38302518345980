import {
  Button,
  type RenderItem,
  type SortableItem,
  SortableTreeviewItem,
  SortableTreeviewVirtualizedDnd,
  type SortableTreeviewVirtualizedDndProps,
  TreeviewItem,
} from '@motion/ui/base'
import { useModalApi } from '@motion/web-common/modals'

import { memo, useCallback } from 'react'
import { Link, useParams } from 'react-router-dom'

import { NewProjectItem, ProjectItem } from './components'

import { useWorkspacesTreeviewContext, useWorkspaceTree } from '../../hooks'

const isSelected = (param: string | undefined, itemId: string) =>
  param === itemId

export const WorkspacesTreeView = memo(function WorkspacesTreeView() {
  const { workspaceId, projectId } = useParams<{
    projectId: string
    workspaceId: string
  }>()
  const modalApi = useModalApi()

  const {
    searchQuery,
    items,
    handleDrop,
    handleClickExpand,
    highlightedId,
    containerRef,
  } = useWorkspacesTreeviewContext()
  const treeItems = useWorkspaceTree(items, searchQuery)

  const renderItem: SortableTreeviewVirtualizedDndProps<
    (typeof treeItems)[number]
  >['renderItem'] = useCallback(
    (item, activeId, onClick, hovering) => {
      const selected =
        isSelected(projectId ?? workspaceId, item.id) && activeId !== item.id

      return (
        <SortableTreeviewItem
          item={item}
          activeId={activeId}
          expanded={item.expanded}
          onClick={onClick}
          hovering={hovering}
          icon={item.icon}
          selected={selected}
          withSpacer={Boolean(item.project)}
        >
          {item.project ? (
            <ProjectItem
              item={item}
              selected={selected}
              highlighted={highlightedId === item.id}
              disabledMoveActions={item.disabledMoveActions}
            />
          ) : (
            <Link
              to={item.url}
              className='h-full min-w-0'
              draggable='false'
              data-workspace-tree-list-item={item.id}
            >
              <TreeviewItem
                label={item.label}
                selected={selected}
                highlighted={highlightedId === item.id}
              />
            </Link>
          )}
        </SortableTreeviewItem>
      )
    },
    [projectId, workspaceId, highlightedId]
  )

  if (searchQuery.length > 0 && items.length === 0) {
    return (
      <div className='h-full flex flex-col gap-1 overflow-auto text-sm text-semantic-neutral-text-subtle p-3'>
        <div>No results</div>
        <div>Try a different search term.</div>
        <div className='pt-2'>
          <Button
            variant='outlined'
            sentiment='neutral'
            size='small'
            onClick={() => modalApi.open('search')}
          >
            Search tasks instead
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div ref={containerRef} className='flex min-h-0 flex-grow w-full'>
      <SortableTreeviewVirtualizedDnd
        items={treeItems}
        onDrop={handleDrop}
        onClickExpand={handleClickExpand}
        itemHeight={34}
        renderItem={renderItem as RenderItem<SortableItem>}
        renderPlaceholder={(item: SortableItem) => (
          <NewProjectItem workspaceId={item.id} />
        )}
        disableDrag={!!searchQuery}
        disableDragToAnotherGroup
      />
    </div>
  )
})
