import {
  useDebouncedCallback,
  useDependantState,
} from '@motion/react-core/hooks'
import {
  useMyUserSettings,
  useUpdateFolderSettings,
} from '@motion/web-common/settings'

import {
  createContext,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react'

import type { GlobalSidebarState } from '../hooks'

type FolderState = {
  expanded: boolean
}

type GlobalSidebarContext = {
  openedPanel: keyof GlobalSidebarState['sections'] | null
  setOpenedPanel: (panel: keyof GlobalSidebarState['sections'] | null) => void
  isInitialLoading: boolean
  folderState: Record<string, FolderState>
  updateFolderState: (data: FolderState & { folderId: string }) => void
}

export const GlobalSidebarContext = createContext<GlobalSidebarContext>({
  openedPanel: null,
  setOpenedPanel: () => void 0,
  isInitialLoading: true,
  folderState: {},
  updateFolderState: () => void 0,
})

export const GlobalSidebarContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [openedPanel, setOpenedPanel] =
    useState<GlobalSidebarContext['openedPanel']>(null)
  const { data: mySettings, isInitialLoading } = useMyUserSettings()
  const { mutate: mutateFolderState } = useUpdateFolderSettings()

  const [folderState, setOptimisticFolderState] = useDependantState<
    GlobalSidebarContext['folderState']
  >(() => mySettings?.folderState ?? {}, [mySettings?.folderState])

  const commitOptimisticUpdates = useDebouncedCallback(
    () => {
      Object.keys(folderState).forEach((folderId) => {
        const currentState = mySettings?.folderState[folderId]
        const optimisticState = folderState[folderId]

        if (
          !currentState ||
          (optimisticState &&
            optimisticState.expanded !== currentState.expanded)
        ) {
          mutateFolderState({ folderId, ...optimisticState })
        }
      })
    },
    500,
    {
      trailing: true,
    }
  )

  const updateFolderState = useCallback<
    GlobalSidebarContext['updateFolderState']
  >(
    ({ folderId, ...data }) => {
      setOptimisticFolderState((prev) => ({
        ...prev,
        [folderId]: data,
      }))

      commitOptimisticUpdates()
    },
    [commitOptimisticUpdates, setOptimisticFolderState]
  )

  const value = useMemo(
    () => ({
      openedPanel,
      setOpenedPanel,
      isInitialLoading,
      folderState,
      updateFolderState,
    }),
    [openedPanel, isInitialLoading, folderState, updateFolderState]
  )

  return (
    <GlobalSidebarContext.Provider value={value}>
      {children}
    </GlobalSidebarContext.Provider>
  )
}
