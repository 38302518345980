import { useDebouncedCallback } from '@motion/react-core/hooks'
import { type WorkspacesAndProjectsOrderedSchema } from '@motion/rpc-types'
import { type OnDropArgs } from '@motion/ui/base'
import { computeSearchScore } from '@motion/ui-logic'
import { byProperty, Compare } from '@motion/utils/array'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'
import { type ProjectSchema } from '@motion/zod/client'

import { arrayMove } from '@dnd-kit/sortable'
import { useSidebarSearchContext } from '~/areas/search/hook'
import {
  type OrderedWorkspaceProjectList,
  useOrderedWorkspaceProjectList,
} from '~/global/hooks'
import { useUpdateUserSettings } from '~/global/rpc'
import {
  createContext,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

const VALID_PAGE_LINKS = [
  'Calendar',
  'My Tasks',
  'All Tasks',
  'All Projects',
  'Team Schedule',
  'Tutorials',
]

type WorkspacesTreeviewContextApi = {
  items: OrderedWorkspaceProjectList
  pageLinks: (typeof VALID_PAGE_LINKS)[number][]
  searchQuery: string
  handleDrop: (args: OnDropArgs) => boolean
  handleMoveProjectByDirection: (
    workspaceId: string | undefined,
    projectId: string | undefined,
    direction: 1 | -1
  ) => void
  handleMoveWorkspaceByDirection: (
    workspaceId: string | undefined,
    direction: 1 | -1
  ) => void
  updateWorkspaceStatusFilters: (
    workspaceId: string,
    newFilteredStatusIds: string[] | null
  ) => void
  handleClickExpand: (workspaceId: string) => void
  setSearchQuery: (value: string) => void
  updateHighlightedByDirection: (direction: 1 | -1) => void
  selectHighlighted: () => void
  highlightedId: string | null
  removeHighlighted: () => void
  containerRef?: React.MutableRefObject<HTMLDivElement | null>
}

export const WorkspacesTreeviewContext =
  createContext<WorkspacesTreeviewContextApi>({
    items: [],
    pageLinks: [],
    searchQuery: '',
    handleDrop: () => false,
    handleMoveProjectByDirection: () => null,
    handleMoveWorkspaceByDirection: () => null,
    updateWorkspaceStatusFilters: () => null,
    handleClickExpand: () => null,
    setSearchQuery: () => null,
    updateHighlightedByDirection: () => null,
    selectHighlighted: () => null,
    highlightedId: null,
    removeHighlighted: () => null,
  })

type WorkspacesTreeviewContextProviderProps = {
  children: ReactNode
}

export const WorkspacesTreeviewContextProvider = ({
  children,
}: WorkspacesTreeviewContextProviderProps) => {
  const { hasSearch, searchQuery, setSearchQuery } = useSidebarSearchContext()

  const highlightedIndex = useRef(-1)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [highlightedId, setHighlightedId] = useState<string | null>(null)

  const { mutate } = useUpdateUserSettings()
  const { data: sortedWorkspaces, isLoading } = useOrderedWorkspaceProjectList()
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(sortedWorkspaces)

  const highlightIndex = useCallback((newIndex: number) => {
    requestAnimationFrame(() => {
      if (!containerRef.current) return
      const items = containerRef.current.querySelectorAll(
        '[data-workspace-tree-list-item]'
      ) as unknown as HTMLAnchorElement[]
      if (newIndex < 0 || newIndex >= items.length) return
      highlightedIndex.current = newIndex
      setHighlightedId(items[newIndex].dataset['workspaceTreeListItem'] ?? null)
    })
  }, [])

  useEffect(() => {
    if (hasSearch) {
      highlightIndex(0)
    } else {
      setHighlightedId(null)
      highlightedIndex.current = -1
    }
  }, [filteredWorkspaces, highlightIndex, hasSearch])

  const updateFilteredWorkspaces = useCallback(
    (list: OrderedWorkspaceProjectList, searchValue: string) => {
      setFilteredWorkspaces(
        list.reduce<OrderedWorkspaceProjectList>((acc, workspaceInfo) => {
          const workspaceScore = computeSearchScore(
            workspaceInfo.item.name,
            searchValue
          )

          const newProjects = workspaceInfo.item.projects
            .reduce<{ score: number; item: ProjectSchema }[]>(
              (accProjects, project) => {
                const includeViaStatus =
                  project.statusId &&
                  workspaceInfo.filteredStatusIds.includes(project.statusId)

                if (includeViaStatus) {
                  const projectScore = computeSearchScore(
                    project.name,
                    searchValue
                  )

                  if (searchValue === '' || projectScore > 0) {
                    accProjects.push({
                      score: projectScore,
                      item: project,
                    })
                  }
                }

                return accProjects
              },
              []
            )
            .sort(byProperty('score', Compare.numeric.desc))
            .map((s) => s.item)

          if (
            searchValue === '' ||
            workspaceScore > 0 ||
            newProjects.length > 0
          ) {
            acc.push({
              ...workspaceInfo,
              expanded: workspaceInfo.expanded || searchValue.length > 0,
              item: {
                ...workspaceInfo.item,
                projects: newProjects,
              },
            })
          }

          return acc
        }, [])
      )
    },
    []
  )

  useEffect(() => {
    // We do this in an effect because `sortedWorkspaces` can change and we'd need to update our internal state
    updateFilteredWorkspaces(sortedWorkspaces, searchQuery)
  }, [sortedWorkspaces, searchQuery, updateFilteredWorkspaces])

  const debounceMutation = useDebouncedCallback(
    (newItems: OrderedWorkspaceProjectList) => {
      // If we have a search query, we don't want to hit the network with the mutation
      if (searchQuery) return

      const workspacesAndProjectsOrdered: WorkspacesAndProjectsOrderedSchema[] =
        newItems.map((workspaceInfo) => {
          return {
            workspaceId: workspaceInfo.item.id,
            workspaceExpanded: workspaceInfo.expanded,
            projectIdsOrdered: workspaceInfo.item.projects.map((p) => p.id),
            filteredStatusIds: workspaceInfo.filteredStatusIdsIsDefault
              ? undefined
              : workspaceInfo.filteredStatusIds,
          }
        })

      mutate({
        sidebarState: { workspacesAndProjectsOrdered },
      })
    },
    250,
    { leading: true }
  )

  const contextValue = useMemo<WorkspacesTreeviewContextApi>(() => {
    const handleDrop = ({
      fromContainerIndex: fromWorkspaceIndex,
      toContainerIndex: toWorkspaceIndex,
      fromItemIndex: fromProjectIndex,
      toItemIndex: toProjectIndex,
    }: OnDropArgs): boolean => {
      // Don't allow updating when there's a search
      if (searchQuery) return false

      // Moving a project to another workspace is currently not supported
      if (
        fromWorkspaceIndex !== toWorkspaceIndex &&
        fromProjectIndex != null &&
        toProjectIndex != null
      ) {
        return false
      }

      if (fromProjectIndex != null && toProjectIndex != null) {
        recordAnalyticsEvent('REORDER_PROJECT_SIDEBAR')
      } else {
        recordAnalyticsEvent('REORDER_WORKSPACE_SIDEBAR')
      }

      let newWorkspaces = filteredWorkspaces

      // Moving a workspace
      if (fromWorkspaceIndex !== toWorkspaceIndex) {
        newWorkspaces = arrayMove(
          newWorkspaces,
          fromWorkspaceIndex,
          toWorkspaceIndex
        )
      }
      // Moving a project within a workspace
      else if (
        fromWorkspaceIndex === toWorkspaceIndex &&
        fromProjectIndex != null &&
        toProjectIndex != null
      ) {
        const workspace = newWorkspaces[fromWorkspaceIndex]
        if (!workspace) return false

        workspace.item.projects = arrayMove(
          workspace.item.projects,
          fromProjectIndex,
          toProjectIndex
        )
      }

      setFilteredWorkspaces(newWorkspaces)
      debounceMutation(newWorkspaces)
      return true
    }

    const handleMoveWorkspaceByDirection = (
      workspaceId: string | undefined,
      direction: 1 | -1
    ) => {
      // Don't allow updating when there's a search
      if (searchQuery) return

      if (!workspaceId) return

      const indexA = filteredWorkspaces.findIndex(
        (w) => w.item.id === workspaceId
      )
      if (indexA < 0) return
      const indexB = indexA + direction
      if (indexB < 0 || indexB > filteredWorkspaces.length - 1) return

      handleDrop({ fromContainerIndex: indexA, toContainerIndex: indexB })
    }

    const handleMoveProjectByDirection = (
      workspaceId: string | undefined,
      projectId: string | undefined,
      direction: 1 | -1
    ) => {
      // Don't allow updating when there's a search
      if (searchQuery) return

      if (!workspaceId || !projectId) return

      const workspaceIndex = filteredWorkspaces.findIndex(
        (w) => w.item.id === workspaceId
      )
      const workspace = filteredWorkspaces[workspaceIndex]
      if (!workspace) return

      const indexA = workspace.item.projects.findIndex(
        (x) => x.id === projectId
      )
      if (indexA < 0) return
      const indexB = indexA + direction
      if (indexB < 0 || indexB > workspace.item.projects.length - 1) return

      handleDrop({
        fromContainerIndex: workspaceIndex,
        toContainerIndex: workspaceIndex,
        fromItemIndex: indexA,
        toItemIndex: indexB,
      })
    }

    const updateWorkspaceStatusFilters = (
      workspaceId: string,
      newFilteredStatusIds: string[] | null
    ) => {
      // Don't allow updating when there's a search
      if (searchQuery) return

      // Use the sortedWorkspaces rather than filtered ones,
      // so we don't mess with the workspace/project positions
      const newWorkspaces = [...sortedWorkspaces]

      const workspace = newWorkspaces.find((w) => w.item.id === workspaceId)
      if (!workspace) return

      workspace.filteredStatusIdsIsDefault = newFilteredStatusIds === null
      workspace.filteredStatusIds = newFilteredStatusIds ?? []

      updateFilteredWorkspaces(newWorkspaces, searchQuery)
      debounceMutation(newWorkspaces)
    }

    const handleClickExpand = (workspaceId: string) => {
      // Use the sortedWorkspaces rather than filtered ones,
      // so we don't mess with the workspace/project positions
      const newWorkspaces = [...sortedWorkspaces]

      const workspace = newWorkspaces.find((w) => w.item.id === workspaceId)
      if (!workspace) return

      workspace.expanded = !workspace.expanded

      updateFilteredWorkspaces(newWorkspaces, searchQuery)
      debounceMutation(newWorkspaces)
    }

    function updateHighlightedByDirection(direction: -1 | 1) {
      const newIndex = highlightedIndex.current - direction
      highlightIndex(newIndex)
    }
    function removeHighlighted() {
      setHighlightedId(null)
      highlightedIndex.current = -1
    }

    function selectHighlighted() {
      const items = document.body.querySelectorAll(
        '[data-workspace-tree-list-item]'
      ) as unknown as HTMLAnchorElement[]
      if (items[highlightedIndex.current]) {
        items[highlightedIndex.current].click()
      }
      setHighlightedId(null)
    }

    const filteredLinks = searchQuery
      ? VALID_PAGE_LINKS.filter(
          (link) => computeSearchScore(link, searchQuery) > 0
        )
      : VALID_PAGE_LINKS

    return {
      items: filteredWorkspaces,
      pageLinks: filteredLinks,
      handleDrop,
      handleMoveProjectByDirection,
      handleMoveWorkspaceByDirection,
      updateWorkspaceStatusFilters,
      handleClickExpand,
      searchQuery,
      setSearchQuery,
      updateHighlightedByDirection,
      selectHighlighted,
      highlightedId,
      removeHighlighted,
      highlightIndex,
      containerRef,
    }
  }, [
    filteredWorkspaces,
    searchQuery,
    setSearchQuery,
    debounceMutation,
    sortedWorkspaces,
    updateFilteredWorkspaces,
    highlightedId,
    highlightIndex,
  ])

  if (isLoading) return <div className='h-full' />

  return (
    <WorkspacesTreeviewContext.Provider value={contextValue}>
      {children}
    </WorkspacesTreeviewContext.Provider>
  )
}
