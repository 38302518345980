import { type UploadedFileSchema } from '@motion/rpc-types'
import { fileIsPreviewable } from '@motion/ui/base'

import {
  useAttachmentActions,
  useDownloadAttachment,
} from '~/areas/attachments/hooks'
import { useFileById } from '~/global/rpc/v2/files'

import { useAttachmentsNavigation } from './use-attachments-navigation'

type UseAttachmentsPreviewParams = {
  attachmentIds: UploadedFileSchema['id'][]
  initialAttachmentId: UploadedFileSchema['id']
}

export function useAttachmentsPreview({
  attachmentIds,
  initialAttachmentId,
}: UseAttachmentsPreviewParams) {
  const {
    currentAttachmentId,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
  } = useAttachmentsNavigation({
    attachmentIds,
    initialAttachmentId,
  })

  const { data: currentAttachment } = useFileById({
    id: currentAttachmentId,
  })

  const { getFileUrl } = useDownloadAttachment()

  const attachmentActions = useAttachmentActions()

  const currentAttachmentUrl = getFileUrl(currentAttachmentId) || ''

  const currentAttachmentIsPreviewable = fileIsPreviewable(
    currentAttachment?.mimeType
  )

  const deleteAttachment = async () => {
    if (!currentAttachment) return

    attachmentActions.deleteAttachment(currentAttachment)
  }

  const downloadAttachment = async () => {
    if (!currentAttachment) return

    attachmentActions.downloadAttachment(currentAttachment)
  }

  const copyAttachmentLink = () => {
    if (!currentAttachment) return

    attachmentActions.copyAttachmentLink(currentAttachment)
  }

  return {
    currentAttachment,
    currentAttachmentUrl,
    currentAttachmentIsPreviewable,
    hasPrevAttachment,
    hasNextAttachment,
    goToPrevAttachment,
    goToNextAttachment,
    deleteAttachment,
    downloadAttachment,
    copyAttachmentLink,
  }
}
