import { BlockedBySolid, BlocksSolid } from '@motion/icons'
import { type PMTaskType } from '@motion/rpc/types'
import { classed } from '@motion/theme'
import { CustomEventHandlers, useRichTextEditor } from '@motion/ui/rte'
import { recordAnalyticsEvent } from '@motion/web-base/analytics'

import { TemplateSidebarTasks } from '~/areas/project-management/components/template-sidebar-tasks'
import { useController } from 'react-hook-form'

import { SidebarLabel } from './expanded-task-sidebar-label'
import { SidebarRow } from './expanded-task-sidebar-row'
import { FormAssigneeDropdown } from './form-assignee-dropdown'
import { FormAutoSchedulePanel } from './form-autoschedule-panel'
import { FormBlockersDropdownContainer } from './form-blockers-dropdown-container'
import { FormChunkDropdown } from './form-chunk-dropdown'
import { FormDeadlineDateDropdown } from './form-deadline-date-dropdown'
import { FormDescriptionField } from './form-description-field'
import { FormDurationDropdown } from './form-duration-dropdown'
import { FormLabelDropdown } from './form-label-dropdown'
import { FormNameField } from './form-name-field'
import { FormPriorityDropdown } from './form-priority-dropdown'
import { FormScheduleDropdown } from './form-schedule-dropdown'
import {
  DateDropdownButton,
  FormStartDateDropdown,
} from './form-start-date-dropdown'
import { FormStatusDropdown } from './form-status-dropdown'
import { RichTextToolbar } from './richtext-toolbar'

import { useUpdateTemplateProjectTask } from '../../../hooks'
import {
  useTaskModalBlockers,
  useTaskModalProjectTasks,
  useTemplateTaskForm,
} from '../hooks'

export const ExpandedTaskContent = () => {
  const { form, actions } = useTemplateTaskForm()
  const { uploadImage, openLinkModalPrompt } = actions

  const { control, watch } = form

  const { field } = useController({
    name: 'description',
    control,
    rules: {
      maxLength: {
        value: 100_000,
        message: 'The description should contain at most 100,000 characters',
      },
    },
  })

  const description = watch('description')

  const editor = useRichTextEditor({
    className: 'min-h-[250px]',
    placeholder: 'Description',
    value: description,
    onChange(value) {
      field.onChange(value)
    },
    extensions: [CustomEventHandlers(uploadImage)],
    onImgExpandClick: () => recordAnalyticsEvent('IMAGE_EXPAND_CLICK'),
  })

  if (!editor) return null

  return (
    <div className='w-full grid grid-cols-[1fr_320px] grid-rows-[fit-content(0)_1fr]'>
      <div className='p-4'>
        <FormNameField />
      </div>
      <StyledAside>
        <TaskFormSidebar />
      </StyledAside>
      <div className='flex flex-col overflow-y-auto'>
        <div className='px-4 pb-4 relative z-10'>
          <div className='pt-1 pb-3 bg-modal-bg sticky top-0 z-10'>
            <RichTextToolbar
              editor={editor}
              onFileUpload={uploadImage}
              onLinkClick={openLinkModalPrompt}
            />
          </div>
          <FormDescriptionField editor={editor} />
        </div>
      </div>
    </div>
  )
}

const TaskFormSidebar = () => {
  const { form, options, hiddenFields } = useTemplateTaskForm()
  const { projectTasks } = useTaskModalProjectTasks()

  const { savedTemplateTask, savedTemplateProject } = options

  const { watch } = form
  const projectId = watch('projectId')
  const templateProjectId = watch('templateProjectId')

  const { blockingTasks, blockedTasks } = useTaskModalBlockers({
    task: savedTemplateTask?.task as PMTaskType,
    projectTasks,
  })

  const updateTemplateProjectTask = useUpdateTemplateProjectTask()

  return (
    <>
      <FormAutoSchedulePanel />
      <MainSidebarGrid>
        <SidebarRow label='Status'>
          <FormStatusDropdown variant='muted' fullWidth />
        </SidebarRow>
        <SidebarRow label='Assignee'>
          <FormAssigneeDropdown variant='muted' fullWidth />
        </SidebarRow>
        <SidebarRow label='Priority'>
          <FormPriorityDropdown variant='muted' fullWidth />
        </SidebarRow>
        <SidebarDivider />
        {!hiddenFields.has('startDate') && (
          <SidebarRow label='Start date'>
            <FormStartDateDropdown>
              {(props) => (
                <DateDropdownButton variant='muted' fullWidth {...props} />
              )}
            </FormStartDateDropdown>
          </SidebarRow>
        )}
        {!hiddenFields.has('deadlineDate') && (
          <SidebarRow label='Deadline'>
            <FormDeadlineDateDropdown>
              {(props) => (
                <DateDropdownButton variant='muted' fullWidth {...props} />
              )}
            </FormDeadlineDateDropdown>
          </SidebarRow>
        )}
        <SidebarDivider />
        <SidebarRow label='Duration'>
          <FormDurationDropdown
            variant='muted'
            fullWidth
            forceHideMinChunksControl
          />
        </SidebarRow>
        {!hiddenFields.has('minChunkDuration') && (
          <SidebarRow label='Min. chunk'>
            <FormChunkDropdown variant='muted' fullWidth />
          </SidebarRow>
        )}
        <SidebarRow label='Labels'>
          <FormLabelDropdown variant='muted' fullWidth />
        </SidebarRow>
        {!hiddenFields.has('scheduleId') && (
          <SidebarRow label='Schedule'>
            <FormScheduleDropdown variant='muted' fullWidth showFullLabel />
          </SidebarRow>
        )}

        {projectId != null || templateProjectId != null ? (
          <>
            <SidebarDivider />

            <div className='col-span-2 flex flex-row items-center'>
              <SidebarLabel
                icon={
                  <BlockedBySolid
                    width={16}
                    className='text-semantic-error-icon-default'
                  />
                }
              >
                Blocked By
              </SidebarLabel>
              <FormBlockersDropdownContainer type='blockedBy' />
            </div>
            <BlockerSection>
              <TemplateSidebarTasks
                tasks={blockingTasks}
                onRemove={(blocker) => {
                  if (savedTemplateTask == null) return

                  void updateTemplateProjectTask(
                    {
                      ...savedTemplateTask.task,
                      templateProjectId: savedTemplateProject?.id ?? '',
                    },
                    {
                      blockingTasks:
                        savedTemplateTask.task.blockingTasks?.filter(
                          (blockingTask) =>
                            blockingTask.blockedId !== blocker.id
                        ) ?? [],
                    }
                  )
                }}
              />
            </BlockerSection>

            <div className='col-span-2 flex flex-row items-center'>
              <SidebarLabel
                icon={
                  <BlocksSolid
                    width={16}
                    className='text-semantic-warning-icon-default'
                  />
                }
              >
                Blocks
              </SidebarLabel>
              <FormBlockersDropdownContainer type='blocks' />
            </div>
            <BlockerSection>
              <TemplateSidebarTasks
                tasks={blockedTasks}
                onRemove={(blocker) => {
                  if (savedTemplateTask == null) return

                  void updateTemplateProjectTask(
                    {
                      ...blocker,
                      templateProjectId: savedTemplateProject?.id ?? '',
                    } as any,
                    {
                      blockingTasks:
                        blocker.blockingTasks?.filter(
                          (blockingTask) =>
                            blockingTask.blockedId !== savedTemplateTask.task.id
                        ) ?? [],
                    }
                  )
                }}
              />
            </BlockerSection>
          </>
        ) : null}
      </MainSidebarGrid>
    </>
  )
}

const StyledAside = classed(
  'aside',
  'row-span-2 border-l border-modal-border overflow-y-auto flex flex-col gap-4 pb-4'
)

const SidebarDivider = classed('div', {
  base: 'border-b border-semantic-neutral-border-subtle my-3 col-span-2',
})

const MainSidebarGrid = classed(
  'div',
  'px-4 gap-x-3 grid grid-cols-[100px_1fr] auto-rows-min items-start'
)

const BlockerSection = classed(
  'section',
  'col-span-2 ml-[21px] mt-2.5 auto-rows-min items-start whitespace-nowrap',
  'first-of-type:pb-5'
)
