import { useController } from 'react-hook-form'

import { TemplateNameField } from '../../../components/task-project-modal'
import { useTemplateTaskForm } from '../hooks'

export const FormTemplateNameField = () => {
  const { form } = useTemplateTaskForm()

  const { control, watch } = form

  const {
    field,
    formState: { errors },
  } = useController({
    name: 'templateName',
    control,
    rules: {
      required: {
        value: true,
        message: 'The template should have a name',
      },
      maxLength: {
        value: 100,
        message: 'The template name should contain at most 100 characters',
      },
    },
  })

  const templateName = watch('templateName')

  return (
    <TemplateNameField
      value={templateName ?? ''}
      onChange={field.onChange}
      hasErrors={errors.templateName != null}
    />
  )
}
